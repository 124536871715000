.User-Data-div {
    width: 700px;
    max-width: 100%;
    padding-right: 50px;
    white-space: normal;
    text-align: left;
    word-wrap: break-word;
}

.User-Data-div p {
    margin: 0;
}

.success {
    border: 2px green solid;
    border-radius: 5px;
    padding: 0 10px;
    margin: 20px;
    width: max-content;
    max-width: 500px;
    white-space: normal;
    text-align: left;
}

.success-message {
    border: 2px green solid;
    border-radius: 5px;
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    max-width: 500px;
    white-space: normal;
    text-align: left;
}


.output-entry {
    margin-left: 20px;
}

.error-message {
    color: red;
    width: max-content;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    white-space: normal;
    text-align: left;
    padding: 4px 6px;
    border: 2px red solid;
    border-radius: 5px;
}

.message-error {
    color: white;
    background-color: red;
    padding: 8px 10px;
    border-radius: 5px;
}

.no-message-error {
    color: white;
    background-color: green;
    padding: 8px 10px;
    border-radius: 5px;
}

.output-value {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 0.95em;
    line-height: normal;
}

.json-key {
    color: #3c5b6a;
}

.json-string {
    color: #5f7c68;
}

.json-number {
    color: #38369A;
}

.json-boolean {
    color: #FF37A6;
}

.json-null {
    color: #020887;
}